<script>
import { Doughnut, mixins } from "vue-chartjs";

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: Object,
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.renderChart(this.chartData, {
        outerHeight: "200px",
        // responsive: false,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
        },
        legend: {
          display: false,
          position: "top",
        },
      });
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler: function () {
        this.render();
      },
    },
  },
};
</script>