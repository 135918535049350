<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: Object,
  },
  mounted() {
    this.render()
  },
  methods: {
    render() {
      this.renderChart(
      this.chartData,
      {
        bezierCurve: false,
        
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "top",
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                color: "#5c6165",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: "#5c6165",
              },
            },
          ],
        },
      }
    );
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler: function() {
        this.render()
      }
    }
  }
};
</script>