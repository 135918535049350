import tensify from 'tensify'

export const getActivity = (l) => {
  let activity = `${l.entity} ${tensify(l.action).past}`
  if (l.parent_entity_name) {
    activity = activity + ` ${l.from_data ? 'from' : 'to'} ${l.parent_entity} ${l.parent_entity_name}`
  }
  return activity
}

export const createSupportForm = () => {
  console.log(window.hbspt)
  window.hbspt.forms.create({
    region: "na1",
    portalId: "20868226",
    formId: "68d1f531-f2f8-48f5-a2b0-a25df799b22a",
    cssClass: "fusion_support_form"
  });
}
