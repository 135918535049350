
import Vue from "vue";

export default Vue.extend({
  name: "DashboardSupport",
  components: {},
  props: {
    height: String,
  },
  data() {
    return {
      supportNumber: process.env.VUE_APP_SUPPORT_NUMBER,
      RMANumber: process.env.VUE_APP_RMA_NUMBER,
      supportHours: process.env.VUE_APP_SUPPORT_HOURS,
    };
  },
  computed: {
    supportNumberTel: function (): string {
      return this.supportNumber.split(" • ")[0];
    },
    RMANumberTel: function (): string {
      return this.RMANumber.split(" • ")[0];
    },
  },
  methods: {
    goToResources() {
      window.open(
        "https://www.geophysical.com/support#helpful-resources",
        "_blank"
      );
    },
    callSupport() {
      window.open(`tel:${this.supportNumberTel}`, "_self");
    },
    callRMA() {
      window.open(`tel:${this.RMANumberTel}`, "_self");
    },
  },
});
